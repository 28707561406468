import React from "react";

import { Link, graphql } from "gatsby";
import TimeAgo from "react-timeago";
import enString from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

const formatter = buildFormatter(enString);

const renderPost = (node) => {
  return (
    <article className="post" key={node.fields.slug}>
      <Link
        className="post-thumbnail"
        style={{
          backgroundImage: `url(${node.frontmatter.image.childImageSharp.fluid.src})`,
        }}
        to={node.fields.slug}
      />
      <div className="post-content">
        <h2 className="post-title">
          <Link to={node.fields.slug}>{node.frontmatter.title || node.fields.slug}</Link>
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
        <span className="post-date">
          {node.frontmatter.author} wrote <TimeAgo date={node.frontmatter.date} formatter={formatter} />
        </span>
        <div className="tagging">
          {node.frontmatter.tag.map((t) => {
            return (
              <Link to={`/tags/${t}`} className="tagging__item">
                #{t}
              </Link>
            );
          })}
        </div>
      </div>
    </article>
  );
};

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"} tagged with "${tag}"`;
  return (
    <div>
      <div className="back-button">
        <Link to="/">← Back to Home</Link>
      </div>

      <div style={{ textAlign: "center" }}>
        <h1>{tagHeader}</h1>
      </div>

      {edges.map(({ node }) => {
        return renderPost(node);
      })}

      <div style={{ textAlign: "center" }}>
        <Link to="/tags">
          <button style={{ fontSize: "1.2rem", border: 0, cursor: "pointer", backgroundColor: "#291312", color: "#fff", padding: "1rem", marginBottom: "1rem", textShadow: "0 -1px 0 rgba(0,0,0,.12)", borderRadius: "1px" }}>All tags</button>
        </Link>
      </div>
    </div>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { tag: { in: [$tag] } },
        fileAbsolutePath: { regex: "/(posts)/" },
        # Show English versions for translated posts or the only version if post has just one language
        fields: {
          shouldDisplay: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            language
            isTranslated
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tag
            author
          }
        }
      }
    }
  }
`;
